
import { Box, Button } from "../../../components/StyledComponent";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import { Link } from "react-router-dom";
import VIDEO_DESKTOP from "../../../assets/videos/Hero Desktop_Background v2.mp4";
import VIDEO_MOBILE from "../../../assets/videos/Hero Mobile_Background v2.mp4";
import { isMobile } from "../../../constants";

const ImageBG = styled.img`

`;

const Showcase = (props: any) => {
  const videoRef: any = useRef(null);
  const [hit, setHit] = useState<boolean>(false);
  const [videoEnded, setVideoEnded] = useState<boolean>(false);

  // handle hit
  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;

    if (hit) {
      video.play();
    }
  }, [hit]);

  const start = () => {
    const video = videoRef.current as HTMLVideoElement;

    const top = video.getBoundingClientRect().top;
    const scrollY = window.scrollY;

    if (top <= scrollY && !videoEnded) {
      setHit(true);
    }
  };

  return (
    <Box
      id={RoutePattern.HOME}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden"
    >
      <Box className="z-1 absolute top-0 left-0 h-full w-full">
        <video
          ref={videoRef}
          preload="metadata"
          playsInline
          autoPlay
          muted
          loop
          src={isMobile ? VIDEO_MOBILE : VIDEO_DESKTOP}
          onEnded={() => setVideoEnded(true)}
          className="h-full w-full object-cover block object-top"
        />
      </Box>
      <Link
       to="/game"
       className="z-2 absolute pb-10 md:pb-0 md:bottom-20"
       reloadDocument={true}
      >
        <Button
         className="mt-2 mx-auto"
         style={{backgroundColor: "#167D3E", borderColor: "#167D3E"}}
        >
          PLAY NOW
        </Button>
      </Link>
    </Box>
  );
};

export default Showcase;
