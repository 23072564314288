import $ from "jquery";
import { Box } from "../../../components/StyledComponent";
import React, { useEffect, useRef, useState } from "react";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import { isMobile } from "../../../constants";
import IMAGE_DESKTOP from "../../../assets/images/iceburg-desktop2.png";
import IMAGE_MOBILE from "../../../assets/images/iceburg-mobile2.png";
import BUBBLE_IMG from "../../../assets/images/text bubble.png";
import ImageMapper from 'react-img-mapper';

import DATA_DESKTOP from './mapping-desktop.json';
import DATA_MOBILE from './mapping-mobile.json';

const BubbleContainer = styled.div`
  z-index: 1;
  top: 0;
  left: -123123123123px;
  position: absolute;
  display: block;
  width: auto;

  @media (min-width: 768px) {
    max-width: 200px;
  }
`;

const TextBubble = styled.div`
  position: absolute;
  top: 0;
  color: black;
  width: 80%;
  height: 50%;
  margin: 10px;
  font-size: 0.75rem;
  line-height: 1rem;

  svg:hover {
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
  }
`;

const BubbleImage = styled.img`
  width: 10rem;
`;

const StyledLink = styled.a`
  color: blue;

  :hover {
    color: red;
  }
`;

const Game = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const targetHome:any = document.getElementById(RoutePattern.GAME);
  const bubble:any = document.getElementById('bubble-container');
  const [imgsIsLoading, setImgsIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const MAP = {
    name: 'yung-mal-map',
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: (isMobile ? DATA_MOBILE : DATA_DESKTOP),
  };

  const reloadWindowIfResized = () => {
    setTimeout(function() {
      window.location.reload();
    }, 1000);
  }

  useEffect(() => {
    window.addEventListener('resize', reloadWindowIfResized);
    return () => {
      window.removeEventListener('resize', reloadWindowIfResized)
    }
  }, []);

  useEffect(() => {
    const preloadSrcList: string[] = [
      IMAGE_DESKTOP,
      IMAGE_MOBILE,
      BUBBLE_IMG,
    ];

    cacheImages(preloadSrcList)
  }, [imgsIsLoading]);

  const cacheImages = async (srcArray: any) => {
      const promises = await srcArray.map((src: any) => {
          return new Promise((resolve, reject) => {
              const img = new Image();
              // console.log(src);

              img.src = src;
              img.onload = () =>
                  setTimeout(() => {
                      resolve(img)
                  }, 2000);
              
              if (!img.onerror) {
                setImgsIsLoading(false);
              }

              img.onerror = err => reject(err)
          });
      });
      console.log('imgsIsLoading', imgsIsLoading);

      await Promise.all(promises);

      setImgsIsLoading(false);

      // console.log(imgsIsLoading);
  };

  return (
    <Box
      id={RoutePattern.GAME2}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden"
    >
      <ImageMapper
        src={isMobile ? IMAGE_MOBILE : IMAGE_DESKTOP}
        responsive={true}
        parentWidth={window.innerWidth}
        map={MAP}
        // stayHighlighted={true}
        onMouseEnter={(area: any, index: any, event): void => {
          // if (area.name !== "Yung Mal") {
            const bubble:any = document.getElementById('bubble-container');
            const textBubble:any = document.getElementById('bubble-text');
            const imageBubble:any = document.getElementById('bubble-image');
            if (isMobile && (area.name === "Lil GotIt" || area.name === "Gucci Mane" || area.name === "Yung Mal")) {
              let decLeft = 95;
              let decTop = 20;
              if (area.name === "Lil GotIt" || area.name === "Gucci Mane" || area.name === "Yung Mal") {
                if (window.innerHeight <= 765) {
                  decLeft = 145;
                  decTop = 150;
                } else if (window.innerHeight <= 900) {
                  decLeft = 150;
                  decTop = 150;
                }
                imageBubble.style.transform = "scaleX(-1)";
                textBubble.style.margin = "12px 20px";
                imageBubble.style.height = "155px";
              // } else {
              //   if (window.innerHeight <= 765) {
              //     decLeft = 10;
              //     decTop = -5;
              //   } else if (window.innerHeight <= 900) {
              //     decLeft = 5;
              //     decTop = -10;
              //   }
              //   imageBubble.style.transform = "scale(-1)";
              //   textBubble.style.margin = "50px 13px 12px";
              //   imageBubble.style.height = "135px";
              }
              // console.log(decLeft,decTop);
              bubble.style.left = (event.pageX - decLeft) + 'px';
              bubble.style.top = (event.pageY - decTop) + 'px';
            } else {
              imageBubble.style.transform = "scaleX(1)";
              bubble.style.left = (event.pageX - 15) + 'px';
              if (isMobile) {
                bubble.style.top = (event.pageY - 175) + 'px';
                imageBubble.style.height = "175px";
                textBubble.style.margin = "10px 5px 10px 12px";
                textBubble.style.width = "83%";
              } else {
                bubble.style.top = (event.pageY - 145) + 'px';
                textBubble.style.top = '0';

                if (area.name === "Yung Mal" || area.name === "Gucci Mane") {
                  bubble.style.top = (event.pageY + 5) + 'px';
                  imageBubble.style.transform = 'scale(1, -1)';
                  textBubble.style.top = '40px';
                }
              }
            }

            if (area.message) {
              bubble.style.display = 'block';
              textBubble.innerHTML = `
              ${area.message}
              <br />
              <div style="float: right;">-- ${area.name}</div>
              <br />
              <div class="text-center text-blue hover:text-red hover:underline font-bold"><a href="${area.url}" target="_blank">${area.url ? "click here" : ""}</a></div>
              `;
            } else {
              bubble.style.display = 'none';
            }
          // }
        }}
        onMouseLeave={() => {
          const bubble:any = document.getElementById('bubble-container');
          // bubble.style.display = 'none';
        }}
        onClick={(area: any, index: any, event): void => {
          if (area.name === "Lil Keed") {
            window.open(area.url);
          }
        }}
        onTouchStart={(area: any, index: any, event): void => {
          if (area.name === "Lil Keed") {
            window.open(area.url);
          }
        }}
        onImageClick={(event): void => {
          const bubble:any = document.getElementById('bubble-container');
          bubble.style.display = 'none';
        }}
        onLoad={(imageRef: any, parentDimensions: { width:any, height:any }): void => {
          console.log(imageRef, parentDimensions, imgsIsLoading);
          let coords = $("map.img-mapper-map area:first-child").attr('coords') as string;
          let cntr = 0;
          let checkCoords = setInterval(function() {
            console.log(coords.indexOf("Infinity"));
            // alert(coords.indexOf("Infinity"));
            // console.log(imgsIsLoading);
            if (coords.indexOf("Infinity") > -1) {
              clearInterval(checkCoords);
              window.location.reload();
            }
            if (cntr > 0) {
              $("#site-preloader").fadeOut();
              clearInterval(checkCoords);
            }
            cntr++;
          }, 2000);
        }}
      />
      
      <BubbleContainer
        id="bubble-container"
        className="absolute"
      >
        <BubbleImage
          id="bubble-image"
          src={BUBBLE_IMG}
        ></BubbleImage>
        <TextBubble id="bubble-text"></TextBubble>
      </BubbleContainer>
      
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-screen lg:w-1/4 px-2 my-6 mx-auto">
              <div className="animate-mount shadow-lg relative flex flex-col w-full bg-white text-black text-center outline-none focus:outline-none">
                <div className="absolute pr-3" style={{zIndex: 2, right: 0, cursor: "pointer" }} onClick={() => { setShowModal(false); if (isMobile) { window.location.reload(); } }}>x</div>
                <div className="relative flex-auto p-5" id="yung_mal_msg">
                  You found me - watch my new video now <br /><StyledLink href="https://youtu.be/NvjDlr3aXd8" className="text-primary" target="_blank"><FontAwesomeIcon icon={faLink as any} /> https://youtu.be/NvjDlr3aXd8</StyledLink>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </Box>
  );
};

export default Game;
