import { ButtonHTMLAttributes, HtmlHTMLAttributes } from "react";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  filler?: boolean;
  className?: string;
};

const Button = ({ className, ...props }: ButtonProps) => {
  return (
    <button
      className={`border-2 bg-primary text-black border-primary hover:bg-transparent hover:text-white py-2 px-12 font-lucida-grande uppercase whitespace-nowrap text-sm lg:text-lg hover:bg-primary ${className}`}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
