export enum RoutePattern {
  HOME = "home",
  GAME = "game",
  GAME2 = "game2",

  ABOUT = "about",
  MUSIC = "music",
  VIDEOS = "videos",
  SUBSCRIBE = "subscribe",
}
